import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const comingfromFifa = () => {
  return (
    <Layout>
      <SEO
        title="Coming from FIFA’s Ultimate Team"
        description="New to myClub? Learn the main differences between FIFA's Ultimate Team and PES myClub."
      />
      <div className="container">
        <h1>Coming from FIFA's Ultimate Team</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Coming from FIFA's Ultimate Team</li>
        </ul>
        <p>
          First of all, welcome! PES myClub is quite different from Ultimate
          Team, so in this page you will learn a few things you should keep in
          mind.
        </p>
        <h2>#1: There is no Market.</h2>
        <p>
          At least, not the Market you’re expecting. You won’t be able to buy
          players from other users, or sell your own. The way you build your
          team is by either opening packs with the help of Agents, or by
          combining Scouts.
        </p>
        <p>There aren't any SBCs either.</p>
        <h2>#2: There are no Chemistry, Healing or Position cards.</h2>
        <p>
          You don’t have Chemistry cards (like “Hunter” or “Shadow”) to boost
          skills in Pro Evolution Soccer. Players’ skills can still be boosted,
          but you might want to have a look at{" "}
          <Link to="/player-form-condition-rating/">Player Form</Link> for that.
        </p>
        <p>
          Similarly, injuries happen VERY rarely in this game, and there are no
          healing cards provided as they aren’t needed.
        </p>
        <p>
          You also won’t need to worry about the position of your players -
          assuming you’re getting a versatile player, he will perform in similar
          positions just as well, sometimes even better. More on this below
          (check #4: You can actually build your “Ultimate Team” here).
        </p>
        <h2>
          #3: Getting good players and managers is easy. Keeping them is the
          hard part.
        </h2>
        <p>
          Most PES players agree that getting a stacked team isn’t hard at all.
          You can get Black Balls (the best players) often, but you’ll quickly
          realize that the cost of keeping them is pretty high (about 3k-4k GP
          per 10 games - that’s about the GP you make if you actually win a
          game).
        </p>
        <p>
          This is the real challenge in PES. To quickly earn more GP, have a
          look at the "How to get more GP" in the{" "}
          <Link to="/myclub-tips-tricks/">Tips and Tricks</Link> section for
          ways to work around this problem.
        </p>
        <p>
          Same goes for Managers - you can spend some coins/GP to get them, but
          you’ll need to renew their contracts every 25 games, should you fail
          to fulfil their objectives. A good manager can set you back 8k GP or
          more for a contract renewal - you will need a very good one if you
          want your team to be filled with Black Balls.
        </p>
        <p>
          Every week you get Featured Players (similar to FIFA’s Team of the
          Week) who performed well in real life, and sometimes Featured Teams,
          highlighting some players from a popular team with higher ratings than
          their normal cards.
        </p>
        <h2>#4: You can actually build your “Ultimate Team” here.</h2>
        <p>
          What I mean by this is that Ronaldo can play alongside Messi without
          any problems. Players from different leagues/nations will play just as
          well - you don’t need to worry if he’s on “7 Chem” or if Ronaldo as LW
          will underperform as CF.
        </p>
        <p>
          Just like in real life, players who are able to play in different
          positions will have that ability in the game. For instance, Messi is
          by default Second Striker, but can play as RWF, AMF or CF. Besides,
          they will get used to a certain position with experience.
        </p>
        <p>
          There are also “Position Trainers”, which are items you can randomly
          gain that will max out a less popular position of a certain player.
        </p>
        <h2>#5: You can level up your favorite players.</h2>
        <p>
          As mentioned, there are no "Hunter" or "Anchor" cards, but you can
          level up your favorite players by training them -{" "}
          <Link to="/training-players/">visit this section to learn more</Link>.
        </p>
        <p>
          This is especially rewarding if you use a squad filled with young
          players.
        </p>
        <h2>#6: There is no Weekend League.</h2>
        <p>
          Yes, that’s right, you can get your sanity back with PES. No need to
          play 30 games in a weekend.
        </p>
        <p>
          There is a PES Weekly League where you can compete for #1 in a
          division table and myClub Open, but that’s about it. The rewards
          aren’t that amazing anyway.
        </p>
      </div>
    </Layout>
  )
}

export default comingfromFifa
